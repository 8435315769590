.container
  max-width 1040px
  margin 0 auto
  

.page-container
  padding 0 20px

.nowrap
  white-space nowrap
