/*------------------------------------*\
    $CARD
\*------------------------------------*/
.card-container
  @extend .cf
  text-align center

.card
  border-radius brand-round
  border 1px solid #626F74
  padding 10px 20px

.card.-large
  padding 33px
