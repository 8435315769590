/*------------------------------------*\
    $OUTLINE
\*------------------------------------*/
/*!*
 * このスタイルは Hugo が出力する目次の
 * HTMLのみに対応しています。
 */
#TableOfContents ul
  &:first-child
    margin-top -12px

#TableOfContents a
  display inline-block
  color #879094
  line-height 1.5
  &:hover
    color #5E9ADD

/**
 * H1
 */
#TableOfContents > ul > li > a
  height 44px
  font-size 14px
  padding-top 12px


/**
 * H2
 */
#TableOfContents > ul > li > ul
  padding-left 20px

#TableOfContents > ul > li > ul > li > a
  height 33px
  font-size 13px
  padding-top 8px

#TableOfContents > ul > li > ul > li > ul
  padding-left 20px

#TableOfContents > ul > li > ul > li > ul > li a
  height 22px
  font-size 13px
  font-weight 200
  padding-top 6px

#TableOfContents .spy-nav > .active > a
  color: #5e9add
  font-weight: 700

#TableOfContents .spy-nav ul.spy-nav
  display: none

#TableOfContents .spy-nav > .active > ul.spy-nav
  display: block

