@charset "UTF-8"
/*------------------------------------*\
    main.styl
\*------------------------------------*/
/*!*
 *
 * SIROK, Inc. - http://sirok.co.jp/
 *
 */
/**
 * Growthbeat Document
 *
 * LICENSE
 *
 * MIT © SIROK, Inc.
 */


/**
 * Please consider the specification of CSS. These stylus files are imported
 * on the basis of ITCSS so specifiency order is increasing.
 * Please see each section description.
 *
 * 1. settings  ... Global variables, config switches
 * 2. tools  ...... Default mixins, functions and tools
 * 3. generic  .... Ground-zero style ( normalize.css )
 * 4. base  ....... Unclassed HTML element
 * 5. objects   ... Objects
 * 6. components .. Components
 * 6. trumps ..... Overrides
 */


/**
 * Libraries
 */
@import "../../node_modules/bdash/stylus/bdash"


/**
 * Settings
 */
@import "settings/*"


/**
 * Base
 */
@import "base/*"


/**
 * Objects
 */
@import "objects/*"


/**
 * Components
 */
@import "components/*"


/**
 * Layouts
 */
@import "layouts/*"


/**
 * Trumps
 */
@import "trumps/*"
