/*------------------------------------*\
    $SDK-CARD
\*------------------------------------*/





/**
 * Card Wrapper
 */
.sdk-cards
  padding 0 20px

  & > .sdk-card
    @extend .card
    display inline-block
    text-align left
    width 22%
    margin 20px 1%
    padding 0
    border-color #C5CDD3


/**
 * Each card
 */
.sdk-cards > .sdk-card
  & > .card-meta
    padding 24px 34px 0

  & > .card-btn
    padding 24px 34px

  & > .card-links
    border-top 1px solid #C5CDD3
    background #F6F9FD
    padding 16px 11px
    text-align center



/**
 * Card meta
 */
.sdk-cards > .sdk-card > .card-meta
  @extend .cf
  & > .image
    display block
    height 62px
    margin 0 auto 20px

  & > .platform
    color #626F74
    font-weight 700
    font-size 20px
    border-bottom 1px solid #C5CDD3
    padding-bottom 5px
    margin-bottom 5px
    text-align center

  & > .sdk-version
      @extend .cf
      & > .version,
      & > .value
        font-weight 100
        color #A8B5BA

      & > .version
        float left

      & > .value
        float right


/**
 * Card Download Button
 */
.card-btn > .btn
  width 100%
  text-align center
  color #5E9ADD
  border-color #5E9ADD
  padding 5px 0
  font-size 14px
  font-weight 900
  letter-spacing .05em
  &:hover
    background #5E9ADD
    color #fff

  &.disabled
    cursor default
    color #e5e5e5
    background-color #fff
    border-color #e5e5e5



/**
 * Card Links
 */
.sdk-cards > .sdk-card > .card-links
  & > .api,
  & > .guide,
  & > .release
    display block
    text-align center
    color #879094
    font-size font-x-small
    line-height font-x-small
    transition all 0.1s ease 0s
    &:hover
      color #5e9add

  & > .guide
    margin-bottom 8px
    padding 4px 0

  & > .api
    margin-bottom 8px
    padding 4px 0
    
  & > .release
    padding 4px 0

  & > .no-link
    color #e5e5e5
    &:hover
      color #e5e5e5

