.faq-header
  margin-bottom 40px

  & > .title
    color #626f74
    font-size 22px
    font-weight 500
    margin-bottom 17px





.faq-content
  margin-bottom (17*2)px

  & > .title
    color #879094
    padding 0 0 12px 17px
    font-size 16px
    margin-bottom 15px
    border-bottom 1px solid #e9eced

    & > .updated
      color #879094
      font-size 12px
      font-weight 400
      float right
      position relative
      top 1px

  & > .summary
    padding 0 20px
    font-size 13px
    line-height 1.5  
    & > p
      margin-bottom 17px

  & > .link
    color #5E9ADD
    border-bottom 1px solid #5E9ADD
    font-size font-small
    margin-left 20px
