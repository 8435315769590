@charset "UTF-8";
/*------------------------------------*    main.styl
\*------------------------------------*/
/**
 *
 * SIROK, Inc. - http://sirok.co.jp/
 *
 */
/**
 * Growthbeat Document
 *
 * LICENSE
 *
 * MIT © SIROK, Inc.
 */
/**
 * Please consider the specification of CSS. These stylus files are imported
 * on the basis of ITCSS so specifiency order is increasing.
 * Please see each section description.
 *
 * 1. settings  ... Global variables, config switches
 * 2. tools  ...... Default mixins, functions and tools
 * 3. generic  .... Ground-zero style ( normalize.css )
 * 4. base  ....... Unclassed HTML element
 * 5. objects   ... Objects
 * 6. components .. Components
 * 6. trumps ..... Overrides
 */
/**
 * Libraries
 */
@charset "UTF-8";
/**
 *
 * SIROK, Inc. - http://sirok.co.jp/
 *
 */
.cf:after,
.nav:after,
.pagination:after,
.card-container:after,
.row:after,
.page:after,
.page-description:after,
.sdk-cards > .sdk-card > .card-meta:after,
.sdk-cards > .sdk-card > .card-meta > .sdk-version:after {
  content: "";
  display: table;
  clear: both;
}
.nav,
.pagination {
  list-style: none;
  margin-left: 0;
}
.nav > li,
.pagination > li,
.nav > li > a,
.pagination > li > a {
  display: inline-block;
}
.nav > li *display:inline,
.pagination > li *display:inline {
  zoom: 1;
}
/* sanitize.css | CC0 Public Domain | github.com/jonathantneal/sanitize.css */
pre,
textarea {
  overflow: auto;
}
[hidden],
audio:not([controls]),
template {
  display: none;
}
details,
main,
summary {
  display: block;
}
input[type=number] {
  width: auto;
}
input[type=search] {
  -webkit-appearance: textfield;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
progress {
  display: inline-block;
}
small {
  font-size: 75%;
}
textarea {
  resize: vertical;
}
[unselectable] {
  user-select: none;
}
*,
::after,
::before {
  box-sizing: inherit;
  border-style: solid;
  border-width: 0;
}
* {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
::after,
::before {
  text-decoration: inherit;
  vertical-align: inherit;
}
:root {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  cursor: default;
  font: 14px/1.4 sans-serif;
  text-rendering: optimizeLegibility;
}
a {
  text-decoration: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  min-height: 1.5em;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}
nav ol,
nav ul {
  list-style: none;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
select::-ms-expand {
  display: none;
}
select::-ms-value {
  color: currentColor;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
::-moz-selection {
  background-color: #b3d4fc;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc;
  text-shadow: none;
}
@media screen {
  [hidden~=screen] {
    display: inherit;
  }
  [hidden~=screen]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}
html {
  min-height: 100%;
  size-adjust: 100%;
  osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font-feature-settings: "liga" on;
}
html,
body {
  height: 100%;
}
@import url("https://fonts.googleapis.com/css?family=Lato:400,100,300,700");
[lang="ja"] body {
  font-family: 'ヒラギノ角ゴ Pro W3' sans-serif, 'Hiragino Kaku Gothic Pro', 'HiraKakuPro-W3';
}
[lang="en"] body {
  font-family: 'Lato', sans-serif, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'HiraKakuPro-W3';
}
.btn {
  position: relative;
  display: inline-block;
  padding: 9px 15px;
  font-size: 12px;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  appearance: none;
  letter-spacing: 0.1em;
  background: #fff;
  color: #dde0e6;
  border: 1px solid #dde0e6;
  border-radius: 3px;
  transition: all 0.2s linear;
}
.btn:hover {
  text-decoration: none;
  background: #dde0e6;
  color: #fff;
}
.btn:active,
.btn:focus {
  outline: none;
}
.btn > i {
  padding: 0 2.5px;
}
.switch {
  display: none;
}
.switch,
.switch:after,
.switch:before,
.switch *,
.switch *:after,
.switch *:before,
.switch + .switch-btn {
  box-sizing: border-box;
}
.switch::selection,
.switch:after::selection,
.switch:before::selection,
.switch *::selection,
.switch *:after::selection,
.switch *:before::selection,
.switch + .switch-btn::selection {
  background: none;
}
.switch + .switch-btn {
  outline: 0;
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 4em;
  height: 2em;
  background: #fbfbfb;
  border: 1px solid #e8eae9;
  padding: 2px;
}
.switch + .switch-btn:after,
.switch + .switch-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.switch + .switch-btn:after {
  left: 0;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 4px 0 rgba(0,0,0,0.08);
}
.switch + .switch-btn:before {
  display: none;
}
.switch:checked + .switch-btn:after {
  left: 50%;
}
.pagination > .pagination-item {
  margin: 0 0.2em;
}
.pagination > .pagination-item > a {
  vertical-align: middle;
  padding: 6px 14px;
  border-radius: 0.25em;
  border: 1px solid #e6e6e6;
  color: #000;
  transition: background, color, border-color, 0.2s linear;
}
.pagination > .pagination-item > a:hover {
  background: #f2f2f2;
  color: #fff;
  border-color: #ddd;
}
.pagination > .pagination-item.active a {
  background: #71a6f6;
  color: #fff;
  border-color: #ddd;
}
.label {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  position: relative;
  top: 0;
  border: 1px solid #ccc;
  background: #fff;
  width: 24px;
  height: 24px;
  margin-left: 7px;
  line-height: 24px;
  border-radius: 3px;
}
.label:hover {
  background: #efefef;
}
.table {
  width: 100%;
}
.table > .table-header-column,
.table > .table-body-column {
  padding: 12px;
}
.table > .table-header > .table-row {
  text-align: left;
  border-bottom: 2px solid #71a6f6;
}
.table > .table-body > .table-row {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.loading.-circle {
  font-size: 10px;
  text-indent: -9999em;
  border-top: 2px solid #f1f4f8;
  border-right: 2px solid #f1f4f8;
  border-bottom: 2px solid #f1f4f8;
  border-left: 2px solid #71a6f6;
  transform: translateZ(0);
}
.loading,
.loading:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.loading:hover {
  animation: load8 1.1s infinite linear;
}
@-moz-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tab {
  border-bottom: 1px solid #ddd;
  font-size: 0;
}
.tab > .tab-item {
  font-size: 16px;
  margin-bottom: -1px;
  display: inline-block;
  vertical-aling: baseline;
  border-right: 1px solid #ddd;
  padding: 10px 15px;
  color: #83868b;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.tab > .tab-item:hover {
  box-shadow: inset 0 4px #eca934;
  color: #3b372f;
  background: #eee;
}
.tab > .tab-item.-active {
  box-shadow: inset 0 4px #71a6f6;
  border-color: #8d9094;
  background: #fff;
  color: #3b372f;
}
.btn.-fill {
  background: #dde0e6;
  border: 1px solid #dde0e6;
  color: #fff;
}
.btn.-fill:hover {
  opacity: 0.7;
}
.btn.-soft {
  border-radius: 200px;
}
.btn.-hard {
  border-radius: 0;
}
.btn.-small {
  padding: 4px 8px;
}
.btn.-large {
  padding: 14px 28px;
}
.btn.-default {
  color: #4078c0;
  border: 1px solid #33609a;
}
.btn.-default:hover {
  color: #fff;
  background: #4078c0;
}
.btn.-success {
  color: #5cb85c;
  border: 1px solid #439a43;
}
.btn.-success:hover {
  color: #fff;
  background: #5cb85c;
}
.btn.-warning {
  color: #ec971f;
  border: 1px solid #c57a11;
}
.btn.-warning:hover {
  color: #fff;
  background: #ec971f;
}
.btn.-danger {
  color: #e94d32;
  border: 1px solid #cc3116;
}
.btn.-danger:hover {
  color: #fff;
  background: #e94d32;
}
.btn.-fill.-default {
  color: #fff;
  background: #4078c0;
  border: 1px solid #33609a;
}
.btn.-fill.-default:hover {
  background: #4078c0;
}
.btn.-fill.-success {
  color: #fff;
  background: #5cb85c;
  border: 1px solid #439a43;
}
.btn.-fill.-success:hover {
  background: #5cb85c;
}
.btn.-fill.-warning {
  color: #fff;
  background: #ec971f;
  border: 1px solid #c57a11;
}
.btn.-fill.-warning:hover {
  background: #ec971f;
}
.btn.-fill.-danger {
  color: #fff;
  background: #e94d32;
  border: 1px solid #cc3116;
}
.btn.-fill.-danger:hover {
  background: #e94d32;
}
.btn-group {
  font-size: 0;
  display: inline-block;
}
.btn-group > .btn {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.btn-group > .btn:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.btn-group > .btn:not(:first-child):not(:last-child) {
  border-right: none;
  border-left: none;
}
.switch.-ios + .switch-btn {
  border-radius: 2em;
}
.switch.-ios + .switch-btn:after {
  border-radius: 2em;
}
.switch.-ios + .switch-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.switch.-ios + .switch-btn:active:after {
  padding-right: 0.8em;
}
.switch.-ios:checked + .switch-btn {
  background: #86d993;
}
.switch.-ios:checked + .switch-btn:active {
  box-shadow: none;
}
.switch.-ios:checked + .switch-btn:active:after {
  margin-left: -0.8em;
}
.table.-striped > .table-body > .table-row:nth-child(even) {
  background: #f1f4f8;
}
.dashboard-wrapper {
  height: 100%;
}
.dashboard-wrapper > .dashboard-main,
.dashboard-wrapper > .dashboard-aside {
  overflow: auto;
  height: 100%;
  max-height: 100%;
}
.dashboard-wrapper > .dashboard-aside {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 210px;
/**
   * Hack
   *
   * iOS Safari scrolling causes HTML elements with a delay
   */
  transform: translate3d(0, 0, 0);
}
.dashboard-wrapper > .dashboard-main {
  position: relative;
  float: right;
  top: 0;
  width: calc(100% - 210px);
/**
   * Hack
   *
   * iOS Safari scrolling causes HTML elements with a delay
   */
  transform: translate3d(0, 0, 0);
}
@media (max-width: 991px) {
  .dashboard--main {
    width: 100%;
  }
}
/**
 * Settings
 */
/**
 * Base
 */
@import url("https://fonts.googleapis.com/css?family=Lato:400,100,300,700,900");
body {
  font-size: 1em;
  line-height: 2em;
  font-family: 'Lato', sans-serif, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'HiraKakuPro-W3';
}
/**
 * Objects
 */
.breadcrumb {
  padding: 0 20px;
}
.breadcrumb > .item {
  list-style-type: none;
  display: inline-block;
}
.breadcrumb > .item > .link {
  color: #959fa5;
  font-size: 11px;
}
.breadcrumb > .item:not(:last-child)::after {
  display: inline-block;
  content: "";
  height: 16px;
  width: 16px;
  padding: 0 20px;
  margin-left: 5px;
  position: relative;
  top: 0;
  background: url("../img/separator.svg") no-repeat center center;
  vertical-align: middle;
}
.card-container {
  text-align: center;
}
.card,
.index-card > .manual,
.index-card > .sdk,
.index-card > .help,
.manual-card > .service,
.sdk-cards > .sdk-card {
  border-radius: 3px;
  border: 1px solid #626f74;
  padding: 10px 20px;
}
.card.-large,
.index-card > .manual,
.index-card > .sdk,
.index-card > .help,
.manual-card > .service {
  padding: 33px;
}
.row {
  padding: 0 20px;
}
.row > .column {
  float: left;
}
.row > .column-main {
  width: 72%;
  padding: 0 0 0 20px;
  float: right;
}
.row > .column-sidebar {
  width: 28%;
  padding: 0 20px;
}
/**
 * このスタイルは Hugo が出力する目次の
 * HTMLのみに対応しています。
 */
#TableOfContents ul:first-child {
  margin-top: -12px;
}
#TableOfContents a {
  display: inline-block;
  color: #879094;
  line-height: 1.5;
}
#TableOfContents a:hover {
  color: #5e9add;
}
#TableOfContents > ul > li > a {
  height: 44px;
  font-size: 14px;
  padding-top: 12px;
}
#TableOfContents > ul > li > ul {
  padding-left: 20px;
}
#TableOfContents > ul > li > ul > li > a {
  height: 33px;
  font-size: 13px;
  padding-top: 8px;
}
#TableOfContents > ul > li > ul > li > ul {
  padding-left: 20px;
}
#TableOfContents > ul > li > ul > li > ul > li a {
  height: 22px;
  font-size: 13px;
  font-weight: 200;
  padding-top: 6px;
}
#TableOfContents .spy-nav > .active > a {
  color: #5e9add;
  font-weight: 700;
}
#TableOfContents .spy-nav ul.spy-nav {
  display: none;
}
#TableOfContents .spy-nav > .active > ul.spy-nav {
  display: block;
}
.page {
  height: 110px;
}
.page,
.page-description {
  padding: 0 20px;
  margin-bottom: 22px;
}
.page > .title,
.page-description > .title {
  font-size: 33px;
  color: #626f74;
  padding: 35px 0 18px 17px;
  border-bottom: 2px solid #626f74;
}
.page > .title > .updated,
.page-description > .title > .updated {
  float: right;
  padding-top: 9px;
  font-size: 12px;
  font-weight: 200;
  color: #7f8d93;
}
.page > .description,
.page-description > .description {
  color: #879094;
  padding: 48px 0 17px 20px;
  font-size: 16px;
  line-height: 2.1;
}
.page > .edit,
.page-description > .edit {
  float: right;
  color: #6693cd;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 200;
  line-height: 1;
  transition: color, 0.1s ease 0s;
}
.page > .edit:hover,
.page-description > .edit:hover {
  color: #33609a;
  text-decoration: underline;
}
.typeset p,
.typeset table,
.typeset h1,
.typeset h2,
.typeset h3,
.typeset h4,
.typeset h5,
.typeset h6,
.typeset ul,
.typeset ol,
.typeset pre {
  margin-bottom: 12px;
  color: #879094;
  font-weight: 300;
  line-height: 1;
}
.typeset p {
  font-size: 15px;
  line-height: 2.1;
}
.typeset pre {
  font-size: 15px;
  line-height: 1.7;
}
.typeset p > img {
  padding: 0 22px 0 12px;
  margin-top: 11px;
  margin-bottom: 12px;
}
.typeset table {
  width: calc(100% - 20px);
  overflow: auto;
  word-break: normal;
  margin-top: 11px;
  margin-bottom: 12px;
  margin-left: 20px;
  padding-top: 0.56001rem;
  padding-left: 20px;
}
.typeset table thead tr {
  border-bottom: 2px solid #879094;
  font-size: 12px;
}
.typeset table thead tr th {
  text-align: left;
  padding: 0px 17px 12px;
  color: #879094;
  font-size: 11px;
}
.typeset table tbody tr {
  border-bottom: 1px solid #e9eced;
}
.typeset table thead tr th,
.typeset table tbody tr td {
  padding: 17px 21px;
}
.typeset ul,
.typeset ol {
  padding-left: 20px;
}
.typeset ul li,
.typeset ol li {
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 12px;
}
.typeset ul li ul,
.typeset ol li ul {
  margin-top: 17px;
}
.typeset ul + p,
.typeset ul + pre {
  padding: 10px 0 0 20px !important;
}
.typeset p a,
.typeset ul a,
.typeset ol a {
  color: #619bdb;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0) 50%, #81afe2 50%);
  background-position: 0 93%;
  background-repeat: repeat-x;
  background-size: 100% 0.15rem;
  text-shadow: 0.1rem 0 #fff, 0.15rem 0 #fff, -0.1rem 0 #fff, -0.15rem 0 #fff;
}
.typeset h1,
.typeset h2,
.typeset h3,
.typeset h4,
.typeset h5,
.typeset h6 {
  font-weight: 500;
}
.typeset h1:first-child,
.typeset h1:not(:first-child) {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #626f74;
}
.typeset h1:not(:first-child) {
  padding-top: 12px;
}
.typeset h1 + h2 {
  margin-top: 0px !important;
}
.typeset h1 ~ p {
  padding: 11px 0 11px 20px;
}
.typeset h1 + p {
  padding: 11px 0 0 20px;
}
.typeset h2 {
  border-bottom: 1px solid #c5cdd3;
  padding: 6px 0 6px 17px;
  margin-top: 6px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
  color: #626f74;
}
.typeset h2 ~ p,
.typeset h2 ~ pre {
  padding: 11px 0 11px 20px;
}
.typeset h2 + p {
  padding: 0 0 0 20px;
}
.typeset h2 ~ ul,
.typeset h2 ~ ol {
  padding-left: 40px;
}
.typeset h2 + ul {
  padding: 11px 0 0 40px;
}
.typeset h2 ~ table {
  width: calc(100% - 20px);
  margin-left: 20px;
}
.typeset h2 + h3 {
  margin-top: 0px !important;
}
.typeset h3 {
  padding: 4px 0 4px 17px;
  margin-top: 6px;
  font-size: 18px;
  color: #626f74;
}
.typeset h3 ~ p {
  padding: 11px 0 11px 20px;
}
.typeset h3 + p {
  padding: 0 0 0 20px;
}
.typeset h3 ~ h4 {
  padding-left: 20px;
}
.typeset h4 {
  margin-top: 12px;
  font-weight: 700;
}
.typeset h4 ~ p {
  padding: 8px 0 8px 24px;
}
.typeset h4 + p {
  padding: 0 0 0 24px;
}
/**
 * Components
 */
.column-sidebar {
  max-width: 291px;
}
.column-sidebar > .title {
  color: #5e9add;
}
.hljs {
  color: #333;
  background: #f6f9fd;
  -webkit-text-size-adjust: none;
}
pre > .hljs {
  display: block;
  overflow-x: auto;
  padding: 10px !important;
}
code.hljs {
  padding: 0.2em;
  margin: 0;
  font-size: 85%;
}
.hljs-comment,
.diff .hljs-header {
  color: #998;
  font-style: italic;
}
.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
  color: #333;
  font-weight: bold;
}
.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
  color: #008080;
}
.hljs-string,
.hljs-tag .hljs-value,
.hljs-doctag,
.tex .hljs-formula {
  color: #d14;
}
.hljs-title,
.hljs-id,
.scss .hljs-preprocessor {
  color: #900;
  font-weight: bold;
}
.hljs-list .hljs-keyword,
.hljs-subst {
  font-weight: normal;
}
.hljs-class .hljs-title,
.hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
  color: #458;
  font-weight: bold;
}
.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rule .hljs-property,
.django .hljs-tag .hljs-keyword {
  color: #000080;
  font-weight: normal;
}
.hljs-attribute,
.hljs-variable,
.lisp .hljs-body,
.hljs-name {
  color: #008080;
}
.hljs-regexp {
  color: #009926;
}
.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.clojure .hljs-keyword,
.scheme .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
  color: #990073;
}
.hljs-built_in {
  color: #0086b3;
}
.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
  color: #999;
  font-weight: bold;
}
.hljs-deletion {
  background: #fdd;
}
.hljs-addition {
  background: #dfd;
}
.diff .hljs-change {
  background: #0086b3;
}
.hljs-chunk {
  color: #aaa;
}
.index-card {
  padding: 0 20px;
}
.index-card > .manual,
.index-card > .sdk,
.index-card > .help {
  cursor: pointer;
  display: block;
  float: left;
  width: 289px;
  margin: 22px;
  border-color: #619bdb;
  transition: background 0.2s linear;
}
.index-card > .manual:hover,
.index-card > .sdk:hover,
.index-card > .help:hover {
  background: #619bdb;
}
.index-card > .manual:hover path,
.index-card > .sdk:hover path,
.index-card > .help:hover path {
  transition: fill 0.2s linear;
  fill: #fff;
}
.index-card > .manual:hover > .title,
.index-card > .sdk:hover > .title,
.index-card > .help:hover > .title {
  color: #fff;
  transition: color 0.2s linear;
}
.index-card > .manual:hover > .description,
.index-card > .sdk:hover > .description,
.index-card > .help:hover > .description {
  color: #fff;
  transition: color 0.2s linear;
}
.index-card > .manual > svg,
.index-card > .sdk > svg,
.index-card > .help > svg {
  display: block;
  text-align: center;
  margin: 0 auto;
  height: 82px;
  fill: #619bdb;
}
.index-card > .manual > .title,
.index-card > .sdk > .title,
.index-card > .help > .title {
  color: #619bdb;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin: 22px 0;
}
.index-card > .manual > .description,
.index-card > .sdk > .description,
.index-card > .help > .description {
  font-size: 13px;
  text-align: center;
  line-height: 1.7;
  color: #626f74;
}
.manual-card {
  margin-bottom: 80px;
  padding: 0 20px;
}
.manual-card > .service {
  cursor: pointer;
  display: block;
  float: left;
  width: 289px;
  margin: 22px;
  color: #626f74;
  border-color: #619bdb;
  transition: color, background 0.2s linear;
}
.manual-card > .service:hover {
  background: #619bdb;
  color: #fff;
}
.manual-card > .service > .img {
  height: 88px;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.manual-card > .service > .title {
  height: 22px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin: 22px 0 0;
}
.sdk-cards {
  padding: 0 20px;
}
.sdk-cards > .sdk-card {
  display: inline-block;
  text-align: left;
  width: 22%;
  margin: 20px 1%;
  padding: 0;
  border-color: #c5cdd3;
}
.sdk-cards > .sdk-card > .card-meta {
  padding: 24px 34px 0;
}
.sdk-cards > .sdk-card > .card-btn {
  padding: 24px 34px;
}
.sdk-cards > .sdk-card > .card-links {
  border-top: 1px solid #c5cdd3;
  background: #f6f9fd;
  padding: 16px 11px;
  text-align: center;
}
.sdk-cards > .sdk-card > .card-meta > .image {
  display: block;
  height: 62px;
  margin: 0 auto 20px;
}
.sdk-cards > .sdk-card > .card-meta > .platform {
  color: #626f74;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 1px solid #c5cdd3;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.sdk-cards > .sdk-card > .card-meta > .sdk-version > .version,
.sdk-cards > .sdk-card > .card-meta > .sdk-version > .value {
  font-weight: 100;
  color: #a8b5ba;
}
.sdk-cards > .sdk-card > .card-meta > .sdk-version > .version {
  float: left;
}
.sdk-cards > .sdk-card > .card-meta > .sdk-version > .value {
  float: right;
}
.card-btn > .btn {
  width: 100%;
  text-align: center;
  color: #5e9add;
  border-color: #5e9add;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.05em;
}
.card-btn > .btn:hover {
  background: #5e9add;
  color: #fff;
}
.card-btn > .btn.disabled {
  cursor: default;
  color: #e5e5e5;
  background-color: #fff;
  border-color: #e5e5e5;
}
.sdk-cards > .sdk-card > .card-links > .api,
.sdk-cards > .sdk-card > .card-links > .guide,
.sdk-cards > .sdk-card > .card-links > .release {
  display: block;
  text-align: center;
  color: #879094;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.1s ease 0s;
}
.sdk-cards > .sdk-card > .card-links > .api:hover,
.sdk-cards > .sdk-card > .card-links > .guide:hover,
.sdk-cards > .sdk-card > .card-links > .release:hover {
  color: #5e9add;
}
.sdk-cards > .sdk-card > .card-links > .guide {
  margin-bottom: 8px;
  padding: 4px 0;
}
.sdk-cards > .sdk-card > .card-links > .api {
  margin-bottom: 8px;
  padding: 4px 0;
}
.sdk-cards > .sdk-card > .card-links > .release {
  padding: 4px 0;
}
.sdk-cards > .sdk-card > .card-links > .no-link {
  color: #e5e5e5;
}
.sdk-cards > .sdk-card > .card-links > .no-link:hover {
  color: #e5e5e5;
}
.search {
  float: right;
  padding: 0 18px;
  position: relative;
  top: 20px;
}
.search input[type="text"] {
  background: #fff;
  box-shadow: none;
  border-radius: 2px;
  width: 200px;
  padding: 0 10px;
}
.header-nav .aa-dropdown-menu {
  left: -400px !important;
}
/**
 * Layouts
 */
.wrapper {
  min-height: 100%;
  position: relative;
}
.content {
  padding: 11px 0 48px;
}
.markdown {
  padding-bottom: 100px;
}
.faq-header {
  margin-bottom: 40px;
}
.faq-header > .title {
  color: #626f74;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 17px;
}
.faq-content {
  margin-bottom: 34px;
}
.faq-content > .title {
  color: #879094;
  padding: 0 0 12px 17px;
  font-size: 16px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e9eced;
}
.faq-content > .title > .updated {
  color: #879094;
  font-size: 12px;
  font-weight: 400;
  float: right;
  position: relative;
  top: 1px;
}
.faq-content > .summary {
  padding: 0 20px;
  font-size: 13px;
  line-height: 1.5;
}
.faq-content > .summary > p {
  margin-bottom: 17px;
}
.faq-content > .link {
  color: #5e9add;
  border-bottom: 1px solid #5e9add;
  font-size: 13px;
  margin-left: 20px;
}
.footer {
  padding: 10px 20px;
  background: #2d3235;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 9999;
  width: 100%;
}
.footer > .copyright {
  font-size: 14px;
  color: #fff;
}
.guide-header {
  margin-bottom: 40px;
}
.guide-header > .title {
  color: #626f74;
  font-size: 26px;
  margin-bottom: 5px;
}
.navbar-header {
  background: #2d3235;
}
.header-nav {
  height: 70px;
  padding: 0px 20px;
}
.header-nav > .logo {
  float: left;
}
.header-nav > .logo > .link {
  margin-top: 5px;
  padding: 15px 0;
  display: block;
  transition: opacity 0.1s ease 0s;
}
.header-nav > .logo > .link:hover {
  opacity: 0.8;
}
.header-nav > .sighup,
.header-nav > .login,
.header-nav > .feature,
.header-nav > .about,
.header-nav > .inquiry {
  float: right;
  height: 70px;
  line-height: 70px;
}
.header-nav > .login,
.header-nav > .feature,
.header-nav > .about,
.header-nav > .inquiry {
  padding: 0 18px;
}
.header-nav > .sighup {
  padding: 0 0 0 18px;
}
.header-nav > .login > .link,
.header-nav > .feature > .link,
.header-nav > .about > .link,
.header-nav > .inquiry > .link {
  padding: 15px 0 24px;
  transition: color, border, 0.1s ease 0s;
  color: #fff;
  border-bottom: 3px solid #2d3235;
}
.header-nav > .login > .link:hover,
.header-nav > .feature > .link:hover,
.header-nav > .about > .link:hover,
.header-nav > .inquiry > .link:hover {
  color: #619bdb;
  border-color: #619bdb;
}
.header-nav > .sighup > .btn {
  border: 1px solid #619bdb;
  padding: 15px 22px 14px 32px;
  background: transparent;
  color: #619bdb;
  letter-spacing: -0.02em;
  display: inline;
}
.header-nav > .sighup > .btn:hover {
  background: #619bdb;
  color: #fff;
}
.navbar-subheader {
  background: #40474a;
}
.container {
  max-width: 1040px;
  margin: 0 auto;
}
.page-container {
  padding: 0 20px;
}
.nowrap {
  white-space: nowrap;
}
/**
 * Trumps
 */
.fixed {
  position: fixed !important;
  top: 20px !important;
  will-change: transform !important;
}
