.breadcrumb
  padding 0 20px

.breadcrumb > .item
  list-style-type none
  display inline-block

.breadcrumb > .item > .link
  color #959FA5
  font-size 11px

.breadcrumb > .item:not(:last-child)::after
  display inline-block
  content ""
  height 16px
  width 16px
  padding 0 20px
  margin-left 5px
  position relative
  top 0
  background url(../img/separator.svg) no-repeat center center
  vertical-align middle
