/*------------------------------------*\
    $HEADER
\*------------------------------------*/
.navbar-header
  background #2D3235

.header-nav
  height 70px
  padding 0px 20px


.header-nav
  & > .logo
    float left

  & > .logo > .link
    margin-top 5px
    padding 15px 0
    display block
    transition opacity 0.1s ease 0s
    &:hover
      opacity .8


.header-nav
  & > .sighup,
  & > .login,
  & > .feature,
  & > .about
  & > .inquiry
    float right
    height 70px
    line-height 70px

  // Each padding
  & > .login,
  & > .feature,
  & > .about
  & > .inquiry
    padding 0 18px

  & > .sighup
    padding 0 0 0 18px

  // Each link style
  & > .login   > .link,
  & > .feature > .link,
  & > .about   > .link,
  & > .inquiry > .link
    padding 15px 0 24px
    transition color, border, 0.1s ease 0s
    color #fff
    border-bottom 3px solid #2D3235
    &:hover
      color #619BDB
      border-color #619BDB

  & > .sighup  > .btn
    border 1px solid #619BDB
    padding 15px 22px 14px 32px
    background transparent
    color #619BDB
    letter-spacing -0.02em
    display inline
    &:hover
      background #619BDB
      color #fff





/*------------------------------------*\
  $SUBHEADER
*------------------------------------*/
.navbar-subheader
  background #40474A
