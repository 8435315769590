.row
  @extend .cf
  padding 0 20px

  & > .column
    float left

  & > .column-main
    width 72%
    padding 0 0 0 20px
    float: right

  & > .column-sidebar
    width 28%
    padding 0 20px
