.search {
  float: right;
  padding: 0 18px;
  position: relative;
  top: 20px;
}

.search input[type="text"] {
  background: #fff;
  box-shadow: none;
  border-radius: 2px;
  width: 200px;
  padding: 0 10px;
}

.header-nav .aa-dropdown-menu { left: -400px !important }
