/*------------------------------------*\
    $INDEX-CARD
\*------------------------------------*/
.index-card
  padding 0 20px

  & > .manual,
  & > .sdk,
  & > .help
    @extend .card
    @extend .card.-large
    cursor pointer
    display block
    float left
    width 289px
    margin 22px
    border-color #619BDB
    transition background 0.2s linear
    &:hover
      background #619BDB

      & path
        transition fill 0.2s linear
        fill #fff
      & > .title
        color #fff
        transition color 0.2s linear
      & > .description
        color #fff
        transition color 0.2s linear

  & > .manual > svg,
  & > .sdk  > svg,
  & > .help > svg
    display block
    text-align center
    margin 0 auto
    height 82px
    fill #619BDB

  & > .manual > .title,
  & > .sdk  > .title,
  & > .help > .title
    color #619BDB
    font-size 22px
    font-weight 600
    text-align center
    margin 22px 0

  & > .manual > .description,
  & > .sdk  > .description,
  & > .help > .description
    font-size font-small
    text-align center
    line-height 1.7
    color #626F74
