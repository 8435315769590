/**
 * Common
 */
.typeset p,
.typeset table,
.typeset h1,
.typeset h2,
.typeset h3,
.typeset h4,
.typeset h5,
.typeset h6,
.typeset ul,
.typeset ol,
.typeset pre
  margin-bottom 12px
  color #879094
  font-weight 300
  line-height 1

.typeset p
  font-size 15px
  line-height 2.1

.typeset pre
  font-size 15px
  line-height 1.7

.typeset p > img
  padding 0 22px 0 12px
  margin-top 11px
  margin-bottom 12px

/**
 * Table
 */
.typeset table
  width calc(100%  - 20px)
  overflow auto
  word-break normal
  margin-top 11px
  margin-bottom 12px
  margin-left 20px
  padding-top 0.56001rem
  padding-left 20px

.typeset table thead tr
  border-bottom 2px solid #879094
  font-size font-x-small

.typeset table thead tr th
  text-align left
  padding 0px 17px 12px
  color #879094
  font-size 11px

.typeset table tbody tr
  border-bottom 1px solid #E9ECED

.typeset table thead tr th,
.typeset table tbody tr td
  padding 17px 21px



/**
 * List
 */
.typeset ul,
.typeset ol
  padding-left 20px

.typeset ul li,
.typeset ol li
  font-size 16px
  line-height 17px
  margin-bottom 12px

.typeset ul li ul,
.typeset ol li ul
  margin-top 17px

.typeset ul + p,
.typeset ul + pre
  padding 10px 0 0 20px !important



/**
 * Link
 */
.typeset p a,
.typeset ul a,
.typeset ol a
  color #619bdb
  background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0) 50%,lighten(#619bdb,20%) 50%);
  background-position: 0 93%;
  background-repeat: repeat-x;
  background-size: 100% 0.15rem;
  text-shadow 0.1rem 0 #fff,0.15rem 0 #fff,-0.1rem 0 #fff,-0.15rem 0 #fff

/**
 * h1 ~ h6
 */
// Common
.typeset h1
.typeset h2,
.typeset h3,
.typeset h4,
.typeset h5,
.typeset h6
  font-weight 500

// h1
.typeset h1:first-child,
.typeset h1:not(:first-child)
  font-size 24px
  font-weight 600
  margin-bottom 12px
  color #626f74

.typeset h1:not(:first-child)
  padding-top 12px

// h1 の隣の h2
.typeset h1 + h2
  margin-top 0px !important

.typeset h1 ~ p
  padding 11px 0 11px 20px

.typeset h1 + p
  padding 11px 0 0 20px


// h2
.typeset h2
  border-bottom 1px solid #c5cdd3
  padding 6px 0 6px 17px
  margin-top 6px
  margin-bottom 12px
  font-size 20px
  font-weight 500
  color #626f74

.typeset h2 ~ p,
.typeset h2 ~ pre
  padding 11px 0 11px 20px

.typeset h2 + p
  padding 0 0 0 20px

.typeset h2 ~ ul,
.typeset h2 ~ ol
  padding-left 40px

.typeset h2 + ul
  padding 11px 0 0 40px

.typeset h2 ~ table
  width calc(100%  - 20px)
  margin-left 20px

// h2 の隣の h3
.typeset h2 + h3
  margin-top 0px !important


// h3
.typeset h3
  padding 4px 0 4px 17px
  margin-top 6px
  font-size 18px
  color #626f74

.typeset h3 ~ p
  padding 11px 0 11px 20px

.typeset h3 + p
  padding 0 0 0 20px

.typeset h3 ~ h4
  padding-left 20px

// h3 の隣の h4
//.typeset h3 + h4
//  margin-top -17px !important

// h4
.typeset h4
  margin-top 12px
  font-weight 700

.typeset h4 ~ p
  padding 8px 0 8px 24px

.typeset h4 + p
  padding 0 0 0 24px




// /**
//  * H2
//  */
// .typeset h2
//   margin-top (17*4)px
//
// .typeset h2 ~ h3
//   margin-top (17*3)px
//
// .typeset h2 + h3
//   margin-top 0 !important
//
//
// /**
//  * H3
//  */
// .typeset h3
//   border-bottom 1px solid #e9eced
//   padding 40px 0 12px 17px
//   color #879094
//   font-size 16px
//
// .typeset h3 ~ p
// .typeset h3 ~ h4
// .typeset h3 ~ h5
//   padding-left 20px
//
// .typeset h3 ~ h4
// .typeset h3 ~ h5
//   margin-top 51px
//
// .typeset h3 + h4
//   margin-top 0 !important
//
// .typeset h3 ~ pre
//   margin 0 0 17px 20px
//
// .typeset h3 ~ ul,
// .typeset h3 ~ ol
//   padding-left 40px
//
// .typeset h3 ~ table
//   width calc(100%  - 20px)
//   margin-left 20px
//
// .typeset h3 ~ h5
//   margin-top 51px
//
// /**
//  * H3
//  */
// h3 ~ h4 + h5
//   margin-top 0 !important
