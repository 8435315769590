/*------------------------------------*\
    $PAGE
\*------------------------------------*/
page-color = #626F74





/*------------------------------------*\
    #PAGE
\*------------------------------------*/
/**
 * <div class="page">
 *   <h1 class="title">
 *     Title
 *     <span class="updated">Updated ~ </span>
 *   </h1>
 *   <p class="description"> Lorem ipsum dolor sit amet. </p>
 * </div>
 */
.page
  height 110px

.page,
.page-description
  @extend .cf
  padding 0 20px
  margin-bottom 22px

  & > .title
    font-size 33px
    color page-color
    padding 35px 0 18px 17px
    border-bottom 2px solid page-color

    & > .updated
      float right
      padding-top 9px
      font-size font-x-small
      font-weight 200
      color lighten(page-color, 20%)

  & > .description
    color #879094
    padding 48px 0 17px 20px
    font-size 16px
    line-height 2.1

  & > .edit
    float right
    color lighten(color-blue, 20%)
    margin-top 16px
    font-size font-x-small
    font-weight 200
    line-height 1
    transition color, 0.1s ease 0s
    &:hover
      color darken(color-blue, 20%)
      text-decoration underline
