.footer
  padding 10px 20px
  background #2D3235
  text-align center
  position absolute
  bottom 0
  z-index 9999
  width 100%

  & > .copyright
    font-size 14px
    color #fff
